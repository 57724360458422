@import 'font';
@import 'mixins';
@import 'animations';
@import 'variables';
@import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import 'globals';
@import 'header';
@import 'hero';
@import 'client';
@import 'differential';
@import 'solutions';
@import 'testimonial';
@import 'functionality';
@import 'contactus';
@import 'blog';
@import 'footer';
@import 'termsModal';
